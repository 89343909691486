import { render, staticRenderFns } from "./403.vue?vue&type=template&id=ba78a766&"
import script from "./403.vue?vue&type=script&lang=js&"
export * from "./403.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ba78a766')) {
      api.createRecord('ba78a766', component.options)
    } else {
      api.reload('ba78a766', component.options)
    }
    module.hot.accept("./403.vue?vue&type=template&id=ba78a766&", function () {
      api.rerender('ba78a766', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/exception/403.vue"
export default component.exports